import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { tap } from 'rxjs/operators';
import { CargoType } from "src/app/core/interfaces/cargo-type.interface";
import { getUpdatedItems } from "../../../../shared/utils/utils";
import { AdminPagesStateDefaults } from "../../admin.state";
import { CargoTypesService } from "../cargo-types.service";
import { CleanCargoTypes, LoadCargoTypes, UpdateCargoTypeOccCodes } from "./cargo-types.actions";
import { CargoTypesStateModel } from "./cargo-types.model";

@Injectable()
@State<CargoTypesStateModel>({
  name: "cargoTypes",
  defaults: AdminPagesStateDefaults
})
export class CargoTypesState {
  constructor(
    private cargoTypesService: CargoTypesService
  ) {}

  @Selector() static items(state: CargoTypesStateModel) { return state.items; }
  @Selector() static cargoTypesCount(state: CargoTypesStateModel) { return state.totalCount; }
  @Selector() static loading(state: CargoTypesStateModel) { return state.loading; }
  @Selector() static saving(state: CargoTypesStateModel) { return state.saving; }

  @Action(LoadCargoTypes)
  loadCargoTypes(ctx: StateContext<CargoTypesStateModel>, action: LoadCargoTypes) {
    ctx.patchState({
      items: [],
      loading: true,
      totalCount: 0
    });

    return this.cargoTypesService.getCargoTypes(action.unionId, action.cargoTypeId, action.employerId, action.skipCount, action.maxResultsCount).subscribe(
      (response) => {
        ctx.patchState({
          items: response.items,
          loaded: true,
          loading: false,
          totalCount: response.totalCount,
        });
      },
      (error) => {
        ctx.patchState({
          items: [],
          loading: false,
          totalCount: 0
        });
      }
    );
  }

  @Action(CleanCargoTypes)
  cleanCargoTypes(ctx: StateContext<CargoTypesStateModel>) {
    ctx.patchState({
      items: [],
      loading: false,
      loaded: false,
      totalCount: 0
    });
  }

  @Action(UpdateCargoTypeOccCodes)
  updateCargoTypeOccCodes(ctx: StateContext<CargoTypesStateModel>, action: UpdateCargoTypeOccCodes) {
    ctx.patchState({ saving: true });
    
    return this.cargoTypesService.updateCargoType(action.occCodeIds, action.cargoTypeId).pipe(
      tap(
        (response: CargoType) => {
          const state = ctx.getState();
          ctx.patchState({
            items: getUpdatedItems(response, state.items, 'cargoTypeId'),
            saving: false
          });
          action.callbackSuccess();
        },
        (error) => {
          ctx.patchState({
            saving: false
          });
          action.callbackError();
        }
      )
    );
  }
}
