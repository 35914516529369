export class LoadCargoTypes {
  public static readonly type = "[Cargo Types] load cargo types";
  constructor(
    public unionId: number,
    public cargoTypeId: number,
    public employerId: number,
    public skipCount: number,
    public maxResultsCount: number
  ) {}
}

export class CleanCargoTypes {
  public static readonly type = "[Cargo Types] clean cargo types"
}

export class UpdateCargoTypeOccCodes {
  public static readonly type = "[Cargo Types] update occ codes";
  constructor(
    public occCodeIds: number[],
    public cargoTypeId: number,
    public callbackSuccess: () => void,
    public callbackError: () => void
  ) {}
}
